import React from "react"
const SectionLinkOut = ({ sx, to, title }) => {
  return (
    <a
      className="font-semibold"
      href={to}
      target="_blank"
      title={title}
      rel="noreferrer nofollow"
    >
      {title}
    </a>
  )
}

export default SectionLinkOut
