import React from "react"
import Seo from "../components/seo/Seo"

import Section from "../components/section/Section"
import SectionInfo from "../components/section/SectionInfo"
import SectionHeading from "../components/section/SectionHeading"
import SectionParagraph from "../components/section/SectionParagraph"
import SectionGrid from "../components/section/SectionGrid"
import SectionInfoContent from "../components/section/SectionInfoContent"
import Company from "../components/contact/Company"
import SectionList from "../components/section/SectionList"
import SectionLinkOut from "../components/section/SectionLinkOut"
import Email from "../components/contact/Email"

const PrivacyPolicy = () => {
  return (
    <>
      <Seo
        title={"Datenschutz | Avalum"}
        description={
          "Wir freuen uns über Ihren Besuch auf unserer Webseite und Ihr Interesse an dem Leistungsangebot von Avalum. Beim Umgang mit Ihren Daten sind wir um größtmögliche Sicherheit bemüht."
        }
      />
      <Section>
        <SectionGrid>
          <SectionInfo>
            <h1 className="font-medium text-xl mb-4 md:text-2xl lg:text-3xl">
              Datenschutz
            </h1>
            <Company />
            <SectionInfoContent>
              <SectionHeading>Hinweise zum Datenschutz</SectionHeading>
              <SectionParagraph>
                Wir freuen uns über Ihren Besuch auf unserer Webseite und Ihr
                Interesse an dem Leistungsangebot von Avalum. Beim Umgang mit
                Ihren Daten sind wir um größtmögliche Sicherheit bemüht. Die
                Verarbeitung Ihrer Daten erfolgt nach den einschlägigen
                gesetzlichen Bestimmungen. Soweit wir zur Durchführung und
                Abwicklung von Verarbeitungsprozessen Dienstleister in Anspruch
                nehmen, gelten für diese dieselben strengen Regelungen zur
                Einhaltung der Datenschutzgesetze.
              </SectionParagraph>
            </SectionInfoContent>

            <SectionInfoContent>
              <SectionHeading>
                (Externes) Hosting und Content Delivery Networks (CDN)
              </SectionHeading>
              <SectionParagraph>
                Diese Website wird bei einem externen Dienstleister gehostet
                (Hoster). Die personenbezogenen Daten, die auf dieser Website
                erfasst werden, werden auf den Servern des Hosters gespeichert.
                Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen,
                Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten,
                Namen, Websitezugriffe und sonstige Daten, die über eine Website
                generiert werden, handeln. Der Einsatz des Hosters erfolgt zum
                Zwecke der Vertragserfüllung gegenüber unseren potenziellen und
                bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse
                einer sicheren, schnellen und effizienten Bereitstellung unseres
                Online-Angebots durch einen professionellen Anbieter (Art. 6
                Abs. 1 lit. f DSGVO). Unser Hoster wird Ihre Daten nur insoweit
                verarbeiten, wie dies zur Erfüllung seiner Leistungspflichten
                erforderlich ist und unsere Weisungen in Bezug auf diese Daten
                befolgen. Wir setzen folgenden Hoster ein:
              </SectionParagraph>
              <SectionParagraph>
                IONOS SE
                <br />
                Elgendorfer Str. 57
                <br />
                56410 Montabaur
                <br />
                Deutschland
              </SectionParagraph>
              <SectionParagraph>
                Abschluss eines Vertrages über Auftragsverarbeitung Um die
                datenschutzkonforme Verarbeitung zu gewährleisten, haben wir
                einen Vertrag über Auftragsverarbeitung mit unserem Hoster
                geschlossen.
              </SectionParagraph>
            </SectionInfoContent>
            <SectionInfoContent>
              <SectionHeading>Daten zur Leistungserbringung</SectionHeading>
              <SectionParagraph>
                Wir verarbeiten (insbesondere erheben und speichern) nur solche
                personenbezogenen Daten, die uns durch Kontaktaufnahme per
                E-Mail mitgeteilt werden.
              </SectionParagraph>
            </SectionInfoContent>
            <SectionInfoContent>
              <SectionHeading>Speicherdauer</SectionHeading>
              <SectionParagraph>
                Ihre E-Mail-Adressen und alle anderen von Ihnen bereitgestellten
                Informationen werden für die Dauer unserer Website oder kürzer
                gespeichert.
              </SectionParagraph>
            </SectionInfoContent>
            <SectionInfoContent>
              <SectionHeading>Nutzung von Google Analytics</SectionHeading>
              <SectionParagraph>
                Wir verwenden Google Analytics, um die Website-Nutzung zu
                analysieren. Die daraus gewonnenen Daten werden genutzt, um
                unsere Website sowie Werbemaßnahmen zu optimieren. Google
                Analytics wird uns von Google Ireland Limited (Gordon House,
                Barrow Street, Dublin 4, Irland) bereitgestellt. Google
                verarbeitet die Daten zur Website-Nutzung in unserem Auftrag und
                verpflichtet sich vertraglich zu Maßnahmen, um die Sicherheit
                und Vertraulichkeit der verarbeiteten Daten zu gewährleisten.
                Während Ihres Website-Besuchs werden u.a. folgende Daten
                aufgezeichnet:
              </SectionParagraph>
              <SectionList
                listItems={[
                  "Aufgerufene Seiten",
                  "Bestellungen inkl. des Umsatzes und der bestellten Produkte DieErreichung von 'Website-Zielen' (z.B. Kontaktanfragen und Newsletter-Anmeldungen)",
                  "Ihr Verhalten auf den Seiten (beispielsweise Verweildauer, Klicks, Scrollverhalten)",
                  "Ihr ungefährer Standort (Land und Stadt)",
                  "Ihre IP-Adresse (in gekürzter Form, sodass keine eindeutige Zuordnung möglich ist)",
                  "Technische Informationen wie Browser, Internetanbieter, Endgerät und Bildschirmauflösung",
                  "Herkunftsquelle Ihres Besuchs (d.h. über welche Website bzw. über welches Werbemittel Sie zu uns gekommen sind)",
                ]}
              />
              <SectionParagraph>
                Es werden niemals persönliche Daten wie Name, Anschrift oder
                Kontaktdaten an Google Analytics übertragen. Diese Daten werden
                an Server von Google in den USA übertragen. Wir weisen darauf
                hin, dass in den USA datenschutzrechtlich nicht das gleiche
                Schutzniveau wie innerhalb der EU garantiert werden kann. Google
                Analytics speichert Cookies in Ihrem Webbrowser für die Dauer
                von zwei Jahren seit Ihrem letzten Besuch. Diese Cookies
                enthaltene eine zufallsgenerierte User-ID, mit der Sie bei
                zukünftigen Website-Besuchen wiedererkannt werden können. Die
                aufgezeichneten Daten werden zusammen mit der zufallsgenerierten
                User-ID gespeichert, was die Auswertung pseudonymer
                Nutzerprofile ermöglicht. Diese nutzerbezogenen Daten werden
                automatisch nach 14 Monaten gelöscht. Sonstige Daten bleiben in
                aggregierter Form unbefristet gespeichert. Sollten Sie mit der
                Erfassung nicht einverstanden sein, können Sie diese mit der
                einmaligen Installation des{" "}
                <SectionLinkOut
                  to={"https://tools.google.com/dlpage/gaoptout?hl=de"}
                />
                unterbinden oder durch das Ablehnen der Cookies über unseren
                Cookie-Einstellungs-Dialog.
              </SectionParagraph>
            </SectionInfoContent>
            <SectionInfoContent>
              <SectionHeading>Drittanbieter von Cookies</SectionHeading>
              <SectionParagraph>
                Drittanbieter von Cookies, die Ihre Browsing-Aktivitäten auf
                unserer Website mit Cookies verfolgen können, um Ihnen Anzeigen
                basierend auf Ihren Interessen anzuzeigen, wenn Sie im Web
                surfen oder soziale Medien verwenden und um analytische Zwecke
                zu verfolgen.
              </SectionParagraph>
            </SectionInfoContent>
            <SectionInfoContent>
              <SectionHeading>SSL- bzw. TLS-Verschlüsselung</SectionHeading>
              <SectionParagraph>
                Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
                Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
                oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine
                SSL- bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung
                erkennen Sie daran, dass die Adresszeile des Browsers von
                „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in
                Ihrer Browserzeile.
              </SectionParagraph>
            </SectionInfoContent>
            <SectionInfoContent>
              <SectionHeading>Löschung bzw. Sperrung der Daten</SectionHeading>
              <SectionParagraph>
                Wir halten uns an die Grundsätze der Datenminimierung und
                Speicherbegrenzung. Wir speichern Ihre personenbezogenen Daten
                daher nur so lange, wie dies zur Erreichung der hier genannten
                Zwecke erforderlich ist oder wie es die vom Gesetzgeber
                vorgesehenen vielfältigen Speicherfristen vorsehen. Nach
                Fortfall des jeweiligen Zweckes bzw. Ablauf dieser Fristen
                werden die entsprechenden Daten routinemäßig und entsprechend
                den gesetzlichen Vorschriften in der Verarbeitung eingeschränkt
                oder gelöscht.
              </SectionParagraph>
            </SectionInfoContent>
            <SectionInfoContent>
              <SectionHeading>
                Weitergabe personenbezogener Daten
              </SectionHeading>
              <SectionParagraph>
                Eine Weitergabe an Dritte erfolgt nicht, es sei denn wir sind
                zur Weitergabe gesetzlich verpflichtet oder Sie haben uns vorher
                Ihre ausdrückliche Einwilligung in die Weiterleitung erteilt.
              </SectionParagraph>
            </SectionInfoContent>
            <SectionInfoContent>
              <SectionHeading>
                Ihre Rechte auf Auskunft, Berichtigung, Einschränkung, Löschung,
                Datenübertragbarkeit, und Widerspruch Widerspruch
              </SectionHeading>
              <SectionParagraph>
                Wenn Sie Fragen zu Ihren Daten haben, können Sie sich jederzeit
                an uns wenden. Sie haben das Recht, auf Antrag unentgeltlich
                Auskunft über Ihre bei uns gespeicherten Daten zu erhalten.
                Ferner haben Sie das Recht auf Berichtigung unrichtiger Daten,
                gegebenenfalls auf Einschränkung der Verarbeitung und, mit
                Ausnahme von gesetzlich vorgeschriebenen Datenspeicherungen, auf
                Löschung. Sie haben das Recht, die Sie betreffenden
                personenbezogenen Daten, die Sie uns bereitgestellt haben, in
                einem strukturierten, gängigen und maschinenlesbaren Format zu
                erhalten oder an einen anderen Verantwortlichen, soweit dies
                technisch möglich ist, zu übermitteln. Hierzu muss die
                Verarbeitung Ihrer personenbezogener Daten auf der
                Rechtsgrundlage der Einwilligung oder mithilfe automatisierter
                Verfahren erfolgen. Das Recht auf Datenübertragbarkeit gilt
                nicht für eine Verarbeitung personenbezogener Daten, die für die
                Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen
                Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt,
                die dem Verantwortlichen übertragen wurde. Sie haben das Recht,
                aus Gründen, die sich aus Ihrer besonderen Situation ergeben,
                jederzeit gegen die Verarbeitung der Sie betreffenden
                personenbezogenen Daten, die der Rechtsgrundlage der Wahrnehmung
                einer Aufgabe im öffentlichen Interesse oder der Rechtsgrundlage
                des berechtigten Interesses erfolgt, Widerspruch einzulegen.
                Dies gilt auch für ein auf diese Bestimmungen gestütztes
                Profiling. Sie können die Änderungen, Geltendmachung Ihrer
                Rechte oder den Widerruf einer Einwilligung durch entsprechende
                Mitteilung an uns mit Wirkung für die Zukunft vornehmen.
              </SectionParagraph>
            </SectionInfoContent>
            <SectionInfoContent>
              <SectionHeading>
                Recht auf Beschwerde bei einer Aufsichtsbehörde
              </SectionHeading>
              <SectionParagraph>
                Wenn Sie der Ansicht sind, dass die Verarbeitung der Sie
                betreffenden personenbezogenen Daten gegen die DS-GVO verstößt,
                haben Sie gemäß Art. 77 DS-GVO das Recht der Beschwerde bei
                einer Datenschutz-Aufsichtsbehörde. Die für die Avalum und
                Frankfurt am Main zuständige Datenschutz-Aufsichtsbehörde ist:
                <br />
                <br />
                Der Hessische Beauftragte für Datenschutz und
                Informationsfreiheit
                <br />
                Postfach: 3163 65021 Wiesbaden
                <br />
                Telefon: +49 611 1408 - 0
              </SectionParagraph>
            </SectionInfoContent>
            <SectionInfoContent>
              <SectionHeading>
                Änderung unserer Datenschutzbestimmungen
              </SectionHeading>
              <SectionParagraph>
                Wir behalten uns vor, diese Datenschutzerklärung gelegentlich
                anzupassen, damit sie stets den aktuellen rechtlichen
                Anforderungen entspricht oder um Änderungen unserer Leistungen
                in der Datenschutzerklärung umzusetzen, z. B. bei der Einführung
                neuer Services. Für Ihren erneuten Besuch gilt dann die neue
                Datenschutzerklärung.
              </SectionParagraph>
            </SectionInfoContent>
            <SectionInfoContent>
              <SectionHeading>
                Kontaktdaten – Fragen zum Datenschutz
              </SectionHeading>
              <SectionParagraph>
                Bitte wenden Sie sich an unsere Datenschutzbeauftragte, wenn Sie
                Fragen zum Datenschutz haben.
              </SectionParagraph>
              <div className="max-w-xs mt-4">
                <Email />
              </div>
            </SectionInfoContent>
          </SectionInfo>
        </SectionGrid>
      </Section>
    </>
  )
}

export default PrivacyPolicy
